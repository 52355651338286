@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  background-color: black;
}
.app{
  display: flex;
  position: relative;
}

.container{
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  /* margin-right: 20px; */
  max-width: 95vw !important;
  /* min-height: 95vh; */
  height: 95vh;
  flex: 1; /* Allow the container to take up available vertical space */
  overflow-y: auto;
}

/* ::-webkit-scrollbar{
  width: 1px;
}
::-webkit-scrollbar-track{
  background:#e0e0e0 ;
}
::-webkit-scrollbar-thumb{
  background: #888;
}
::-webkit-scrollbar-track:hover{
 background: #555;
} */